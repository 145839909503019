<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-05-27 16:30:36
 * @LastEditTime: 2023-01-09 22:17:20
 * @Description: 信息价合同调价
 * @Param:
 * @FilePath: \BusinessPlatform\src\views\salesBusiness\contractManage\contractPrice\InfoPriceContractPrice.vue
 -->

<template>
    <div
        v-loading="loading"
        class="InfoPriceContractPrice"
        :class="{'procedure': handleType === 'procedure'}"
    >
        <div class="InfoPriceContent">
            <!-- 基础信息 -->
            <div class="table-container">
                <table class="custom-table">
                    <tbody>
                        <tr>
                            <td colspan="4" class="table-tit">
                                <span>基础信息</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="column-tit" style="width: 15%;">
                                合同编号：
                            </td>
                            <td class="tdStyle1" style="width: 35%;" @click="choseContract">
                                <el-input
                                    v-model="formData.contract_number"
                                    :disabled="IsRead || handleType==='edit'"
                                >
                                    <i slot="suffix" class="iconfont icon009"></i>
                                </el-input>
                            </td>
                            <td class="column-tit" style="width: 15%;">
                                工程名称：
                            </td>
                            <td class="tdStyle1" style="width: 35%;">
                                <el-input v-model="formData.engineering_name" readonly></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="column-tit">
                                价格模式：
                            </td>
                            <td>
                                <el-radio-group v-model="formData.price_mode" disabled>
                                    <el-radio :label="0">
                                        企业固定价
                                    </el-radio>
                                    <el-radio :label="1">
                                        市场信息价
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <td class="column-tit">
                                信息价取值：
                            </td>
                            <td>
                                <el-radio-group v-model="formData.get_price_type" disabled>
                                    <el-radio :label="0">
                                        上期信息价
                                    </el-radio>
                                    <el-radio :label="1">
                                        当期信息价
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td class="column-tit">
                                价格取整：
                            </td>
                            <td>
                                <el-radio-group v-model="formData.price_value_type" disabled>
                                    <el-radio :label="0">
                                        取整
                                    </el-radio>
                                    <el-radio :label="1">
                                        小数点后1位
                                    </el-radio>
                                    <el-radio :label="2">
                                        小数点后2位
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <td colspan="2">
                                <el-radio-group v-model="formData.price_rounding_mode" disabled>
                                    <el-radio :label="0">
                                        进一法
                                    </el-radio>
                                    <el-radio :label="1">
                                        四舍五入
                                    </el-radio>
                                    <el-radio :label="2">
                                        舍掉
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td class="column-tit">
                                地区信息价：
                            </td>
                            <td>
                                <el-input-number
                                    disabled
                                    v-model="formData.info_price_regional_name"
                                    :controls="false"
                                ></el-input-number>
                            </td>
                            <td class="column-tit">
                                是否跨区组合：
                            </td>
                            <td>
                                <el-radio-group v-model="formData.is_trans_regional" disabled>
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td class="column-tit">
                                执行调价前价格的金额：
                            </td>
                            <td class="tdStyle1" colspan="3">
                                <el-input
                                    v-model="formData.modification_before_money"
                                    :disabled="IsRead"
                                    type="number"
                                    @mousewheel.native.prevent
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="column-tit">
                                生效时间：
                            </td>
                            <td class="tdStyle1">
                                <el-date-picker
                                    v-model="formData.effective_time"
                                    type="datetime"
                                    placeholder="选择日期"
                                    :disabled="IsRead"
                                    format
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </td>
                            <td class="column-tit">
                                截止时间：
                            </td>
                            <td class="tdStyle1">
                                <el-date-picker
                                    v-model="formData.cutoff_time"
                                    type="datetime"
                                    placeholder="选择日期"
                                    :disabled="IsRead"
                                    format
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 产品标号设置 -->
            <div class="table-container marketInformationPrice">
                <table class="custom-table">
                    <thead>
                        <tr>
                            <td colspan="11" class="table-tit">
                                <span>产品标号设置</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="column-tit">
                                价格模式
                            </th>
                            <th class="column-tit">
                                区域信息价
                            </th>
                            <th class="column-tit">
                                规格型号
                            </th>
                            <th class="column-tit">
                                市场价格
                            </th>
                            <th class="column-tit">
                                优惠比例(%)
                            </th>
                            <th class="column-tit">
                                优惠金额(元)
                            </th>
                            <th class="column-tit">
                                原含税单价(元)
                            </th>
                            <th class="column-tit">
                                含税单价(元)
                            </th>
                            <th class="column-tit">
                                税率(%)
                            </th>
                            <th class="column-tit">
                                除税单价(元)
                            </th>
                            <th class="column-tit">
                                额外可用附加型号
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in formData.ContractPriceMarkNumList">
                            <tr :key="`grade${index}`" :class="{ infoPriceBg: item.price_type == 1, enterprisePriceBg: item.price_type == 0 }">
                                <td>
                                    <template v-if="item.price_type == 0">
                                        固定价
                                    </template>
                                    <template v-else>
                                        信息价
                                    </template>
                                </td>
                                <td>{{ item.region_name }}</td>
                                <td>{{ item.specification_mode_name }}</td>
                                <td>{{ item.market_price }}</td>
                                <td>{{ item.discount_rate }}</td>
                                <td>{{ item.discount_money }}</td>
                                <td>{{ item.original_product_mark_price }}</td>
                                <td>
                                    <el-input
                                        placeholder="请输入"
                                        type="number"
                                        :disabled="IsRead"
                                        v-model="item.included_tax_unit_price"
                                        @mousewheel.native.prevent
                                        @change="processingData(item)"
                                    ></el-input>
                                </td>
                                <td>{{ item.tax_rate }}</td>
                                <td>{{ item.after_tax_unit_price }}</td>
                                <td>
                                    <template v-if="numMoreObjs[item.compose_code]">
                                        <span class="tag-more-text" @click="viewMoreTag(item)">······</span>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="table-container additionalModels">
                <table class="custom-table">
                    <tbody>
                        <tr>
                            <td colspan="8" class="table-tit">
                                <span>附加型号设置</span>
                            </td>
                        </tr>
                        <template v-if="formData.ContractPriceAdditionalList.length>0">
                            <template v-for="(item, index) in formData.ContractPriceAdditionalList">
                                <tr :key="`additional${index}`">
                                    <td class="column-tit" style="width:1.5rem;">
                                        附加型号：
                                    </td>
                                    <td class="tdStyle1">
                                        <el-input
                                            placeholder="—请选择—"
                                            v-model="item.additional_model"
                                            :disabled="IsRead"
                                            readonly
                                        >
                                        </el-input>
                                    </td>
                                    <td>
                                        <el-checkbox
                                            v-model="item.checkAll"
                                            disabled
                                        >
                                            所有标号
                                        </el-checkbox>
                                    </td>
                                    <td class="column-tit" style="width:1.5rem;">
                                        原价格：
                                    </td>
                                    <td class="tdStyle1">
                                        <el-input
                                            placeholder="请输入"
                                            type="number"
                                            v-model="item.original_additional_model_price"
                                            disabled
                                            @mousewheel.native.prevent
                                        ></el-input>
                                    </td>
                                    <td class="column-tit" style="width:1.5rem;">
                                        调整后价格：
                                    </td>
                                    <td class="tdStyle1">
                                        <el-input
                                            placeholder="请输入"
                                            type="number"
                                            v-model="item.current_additional_model_price"
                                            :disabled="IsRead"
                                            @mousewheel.native.prevent
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr v-if="!item.checkAll" :key="`link${index}`">
                                    <td class="column-tit" style="width:1.5rem;">
                                        产品标号：
                                    </td>
                                    <td colspan="6">
                                        <el-checkbox-group v-model="item.checkedProductNumList" class="checkgroup">
                                            <el-checkbox
                                                v-for="(v, i) in item.productNumChosed"
                                                :key="`link${index}${i}`"
                                                :label="v.cpmmid"
                                                disabled
                                            >
                                                {{ v.product_mark_num }}
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
            <!-- 浇筑方式设置 -->
            <div class="table-container pouringMethod">
                <table class="custom-table">
                    <thead>
                        <tr>
                            <th colspan="6" class="table-tit">
                                <span>浇筑方式设置</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="column-tit">
                                地泵(元/m³)：
                            </th>
                            <td class="tdStyle1">
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.ground_pump_price"
                                    type="number"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                            <th class="column-tit">
                                自备泵(元/m³)：
                            </th>
                            <td class="tdStyle1">
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.self_owned_pump"
                                    type="number"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                            <th class="column-tit">
                                吊斗(元/m³)：
                            </th>
                            <td class="tdStyle1">
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.cableway_bucket"
                                    type="number"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div>
                                    <el-radio-group v-model="formData.is_pump_base" :disabled="IsRead">
                                        <el-radio :label="true">
                                            统一汽车泵价格
                                            <el-input
                                                placeholder="请输入"
                                                class="inputStyle1"
                                                v-model="formData.pump_price"
                                                type="number"
                                                :disabled="!formData.is_pump_base"
                                                @mousewheel.native.prevent
                                            ></el-input>
                                            元/m³
                                        </el-radio>
                                        <el-radio :label="false">
                                            泵送高度区间配置
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="custom-table" v-if="!formData.is_pump_base&&formData.is_pump_base!==null">
                    <tbody>
                        <tr>
                            <th>泵送高度(m)</th>
                            <th>泵送价格(元/m³)</th>
                        </tr>
                        <tr v-for="(pump, index) in formData.ContractPricePumpList" :key="`pumpingHeight${index}`">
                            <td class="pump-select cell-center tdStyle1">
                                <el-input class="inputStyle1" v-model="pump.pump_lower" readonly></el-input>
                                <span class="spanStyle1">-</span>
                                <el-input class="inputStyle1" v-model="pump.pump_upper" readonly></el-input>
                            </td>
                            <td class="tdStyle1">
                                <el-input
                                    placeholder="请输入"
                                    v-model="pump.current_pump_price"
                                    type="number"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 运输费用设置 -->
            <div class="table-container transportationCost">
                <table class="custom-table">
                    <thead>
                        <tr>
                            <th colspan="6" class="table-tit">
                                <span>运输费用设置</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="column-tit">
                                我方运输：
                            </th>
                            <td colspan="2">
                                <el-radio-group v-model="formData.is_self_carriage" :disabled="IsRead">
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th class="column-tit">
                                是否含基础运费：
                            </th>
                            <td colspan="2">
                                <el-radio-group v-model="formData.is_included_freight" :disabled="IsRead">
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th class="column-tit">
                                基础运费(元/m³)：
                            </th>
                            <td class="tdStyle1">
                                <el-input
                                    type="number"
                                    placeholder="请输入内容"
                                    v-model="formData.base_freight"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                            <th class="column-tit">
                                基础公里数(km)：
                            </th>
                            <td class="tdStyle1">
                                <el-input
                                    type="number"
                                    placeholder="请输入内容"
                                    v-model="formData.base_mileage"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                            <th class="column-tit">
                                超出运距费用(元/km/m³)：
                            </th>
                            <td class="tdStyle1">
                                <el-input
                                    type="number"
                                    placeholder="请输入内容"
                                    v-model="formData.than_distance"
                                    @mousewheel.native.prevent
                                    :disabled="IsRead"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="column-tit">
                                对方自运优惠(元/m³)：
                            </th>
                            <td colspan="2" class="tdStyle1">
                                <el-input v-model="formData.transport_price" placeholder="请输入内容" :disabled="IsRead"></el-input>
                            </td>
                            <th class="column-tit">
                                拉水(元/车)：
                            </th>
                            <td colspan="2" class="tdStyle1">
                                <el-input v-model="formData.pull_water" placeholder="请输入内容" :disabled="IsRead"></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogVisible"
                width="30%"
                height="30%"
                :modal="false"
                center
            >
                <ul>
                    <li
                        class="tag el_left"
                        v-for="(tag,i) in currentNumMore"
                        :key="`dialog${i}`"
                    >
                        <span class="tag-text" :title="tag">{{ tag }}</span>
                    </li>
                </ul>
            </el-dialog>
        </div>
        <footer>
            <el-button
                type="primary"
                class="save"
                @click="save"
                v-if="IsRead == false"
                :loading="saveBtnLoading"
            >
                保存
            </el-button>
            <el-button class="Close" @click="Close">
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import { toFixedFun } from '@/assets/js/decimals';
import NP from 'number-precision';

export default {
    // 信息价合同调价
    name: 'info-price-contract-price',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            formData: {
                ContractPriceMarkNumList: [],
                ContractPriceAdditionalList: [],
                ContractPriceAdditionalMarkList: [],
                ContractPricePumpList: [],
            }, // 总数据集合
            productNumChosed: [], // 已添加的标号
            dialogVisible: false, // 更多附加型号弹框显示隐藏
            currentNumMore: {}, // 额外可用附加型号数据
            dialogTitle: '', // 更多附加型号标题
            handleType: 'add', // 页面类型 add新增、edit查看、procedure启流程
            // 只读场景
            IsRead: false,
            // 附加型号数据
            numMoreObjs: [],
            pumpingHeightSelectData: [], // 泵送字典数据列表
            // 保存按钮状态
            saveBtnLoading: false,
        };
    },
    created() {
        if (this.extr?.data || this.vueFormParams?.formId) {
            const PKValues = this.extr.data || this.vueFormParams.formId;
            this.getContractPrice(PKValues, null);
            if (this.extr?.data) {
                this.handleType = 'edit';
            } else if (this.vueFormParams?.formId) {
                this.handleType = 'procedure';
            }
        } else {
            this.handleType = 'add';
        }
        if (this.extr?.IsRead || this.vueFormParams?.IsRead) {
            this.IsRead = this.extr.IsRead || this.vueFormParams.IsRead;
        }
        this.getPumpList();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        // 选择合同
        choseContract() {
            if (this.IsRead || this.handleType === 'edit') {return;}
            this.$toast(
                {
                    title: true,
                    type: 'eject',
                    width: '14rem',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'adjustprice_choose_contract' },
                        selectCallback: this.closeCallback,
                    },
                }
            );
        },
        closeCallback(data) {
            this.getContractPrice(null, data.cvid);
        },

        // 获取价格表数据
        getContractPrice(PKValues, ContractVersionId) {
            this.loading = true;
            let queryUrl = '';
            if (PKValues && !ContractVersionId) {
                queryUrl = '?PKValues=' + PKValues;
            }
            if (!PKValues && ContractVersionId) {
                queryUrl = '?ContractVersionId=' + ContractVersionId;
            }
            if (PKValues && ContractVersionId) {
                queryUrl = '?PKValues=' + PKValues + '&ContractVersionId=' + ContractVersionId;
            }
            this.$axios
                .get(`/interfaceApi/sale/contract_price/adjustment_InfoPrice${queryUrl}`)
                .then(res => {
                    if (res) {
                        this.formData = res;
                        // 初始化处理数据
                        this.analysisRelationship();
                        const chosedArr = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList)); // 新数组
                        this.productNumChosed = chosedArr;
                        if (this.formData.ContractPriceAdditionalMarkList.length > 0) {
                            this.renderAdditionalNumcheck();
                            this.productNumChosedAllAdditional();
                        }
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 初始化数据
        analysisRelationship() {
            if (this.formData.ContractPriceMarkNumList.length > 0) {
                this.formData.ContractPriceMarkNumList.forEach(item => {
                    this.processingData(item);
                    if (item.additional_model) {
                        item.additionalModels = item.additional_model.split('+');
                    }
                });
            }
            // 格式化附加型号和产品
            const numMoreObjs = this.numMoreObjs;
            if (Object.keys(numMoreObjs).length === 0) {
                this.formData.ContractPriceAdditionalMarkList.forEach(item => {
                    if (numMoreObjs[item.compose_code]) {
                        numMoreObjs[item.compose_code].push(item.additional_model);
                    } else {
                        numMoreObjs[item.compose_code] = [item.additional_model];
                    }
                });
            }
        },

        // 处理数据
        processingData(data) {
            // 除税单价 = 含税单价*910 
            const new_formula = NP.divide(data.included_tax_unit_price, NP.plus(1, NP.divide(data.tax_rate || 0, 100)));
            data.after_tax_unit_price = this.numericalCalculation(new_formula);
        },

        // 回显-型号选中标号
        renderAdditionalNumcheck() {
            // 初始化 附加型号根据关联关系，绑定关联的信息价
            this.formData.ContractPriceAdditionalList.forEach(z => {
                // 自定义-选中的标号集合
                z.checkedProductNumList = [];
                // 自定义-标号集合
                z.productNumList = this.formData.ContractPriceMarkNumList;
                this.formData.ContractPriceAdditionalMarkList.forEach(item => {
                    if (item.additional_model_code === z.additional_model_code) {
                        z.checkedProductNumList.push(item.compose_code);
                    }
                });
                // 自定义-是否是所有型号
                if (z.checkedProductNumList.length === z.productNumList.length) {
                    z.checkAll = true;
                } else {
                    z.checkAll = false;
                }
            });
        },

        // 回显-为所有附加型号添加所有标号
        productNumChosedAllAdditional() {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                let arr = JSON.parse(JSON.stringify(this.productNumChosed));
                arr = arr.map(v => {
                    v.disable = false;
                    return v;
                });
                item.productNumChosed = arr;
                return item;
            });
        },

        // 查看更多附加型号
        viewMoreTag(data) {
            this.dialogTitle = data.product_mark_num + '-附加型号';
            this.currentNumMore = this.numMoreObjs[data.compose_code];
            this.dialogVisible = true;
        },

        // 获取泵送价格字典
        getPumpList() {
            this.$axios.get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=303-BS0&type=3')
                .then(res => {
                    if (res) {
                        this.pumpingHeightSelectData = res;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存
        async save(JTFlowAfterFormSaveSuccess) {
            this.loading = true;
            const requstData = JSON.parse(JSON.stringify(this.formData));
            requstData.info_price_regional_name = `${this.formData.info_price_regional_name}`;
            this.$axios
                .post('/interfaceApi/sale/contract_price/adjustment_InfoPrice', requstData)
                .then(res => {
                    this.loading = false;
                    if (this.handleType === 'procedure') {
                        window.hackReset();
                        if (JTFlowAfterFormSaveSuccess) {
                            JTFlowAfterFormSaveSuccess(res);
                        }
                    } else {
                        this.$parent.hackReset();
                        this.$parent.hide();
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        Close() {
            this.$parent.hide();
        },

        // 数值计算
        numericalCalculation(formula) {
            let calculationResults = 0;
            if (this.formData.price_value_type === 0) { // 取整
                if (this.formData.price_rounding_mode === 0) {
                    // 进一法
                    calculationResults = Math.ceil(formula);
                } else if (this.formData.price_rounding_mode === 1) {
                    // 四舍五入
                    calculationResults = Math.round(formula);
                } else if (this.formData.price_rounding_mode === 2) {
                    // 舍掉
                    calculationResults = Math.floor(formula);
                }
            } else if (this.formData.price_value_type === 1) { // 小数点后1位
                if (this.formData.price_rounding_mode === 0) {
                    // 进一法
                    calculationResults = NP.divide(Math.ceil(NP.divide(Math.floor(NP.times(formula, 100)), 10)), 10);
                } else if (this.formData.price_rounding_mode === 1) {
                    // 四舍五入
                    calculationResults = toFixedFun(formula, 1);
                } else if (this.formData.price_rounding_mode === 2) {
                    // 舍掉
                    calculationResults = Math.floor(NP.times(formula, 10)) / 10;
                }
            } else if (this.formData.price_value_type === 2) { // 小数点后2位
                if (this.formData.price_rounding_mode === 0) {
                    // 进一法
                    calculationResults = NP.divide(Math.ceil(NP.divide(Math.floor(NP.times(formula, 1000)), 10)), 100);
                } else if (this.formData.price_rounding_mode === 1) {
                    // 四舍五入
                    calculationResults = toFixedFun(formula, 2);
                } else if (this.formData.price_rounding_mode === 2) {
                    // 舍掉
                    calculationResults = NP.divide(Math.floor(NP.times(formula, 100)), 100);
                }
            }
            return calculationResults;
        },
    },
};
</script>
<style lang="stylus">
.InfoPriceContractPrice
    height 100%
    background #fff
    overflow-y hidden
    &.procedure
        padding 0
        overflow-y hidden
        .InfoPriceContent
            height 100%
        footer
            display none
    .el-dialog__header
        background #4f67ad
        color #fff
        padding 10px 20px 10px
        .el-dialog__title
            color #fff
        .el-dialog__headerbtn
            top 10px
            color #fff
            font-size 18px
            .el-dialog__close
                color #fff
    .el-dialog
        height 300px
        .el-dialog__body
            height calc(100% - 52px)
            overflow-y auto
            overflow-x hidden
    .tag
        border 1px solid #EAEAEA
        height .32rem
        line-height .32rem
        margin 0
        margin-left .1rem
        color #333
        cursor pointer
        background #F7F7F7
        margin-bottom .1rem
        margin-top: .1rem;
        .tag-text
            float left
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
            padding 0 .05rem
        .iconguanbi
            font-size .12rem
            color #979EA7
            float right
            margin-right .1rem
            width .14rem
        &:hover
            background #FEF8F8
            border 1px solid #D6000F
            color #D6000F
            .iconguanbi
                color #D6000F
    .InfoPriceContent
        overflow auto
        padding 0.2rem
        height calc(100% - 0.8rem)
        .table-container
            width 100%
            margin-bottom .2rem
            &:last-child
                bottom 0
            &.marketInformationPrice
                table
                    text-align center
                    thead
                        th
                            white-space nowrap
                            text-align center !important
                .infoPriceBg
                    background #F5F2ED
                .enterprisePriceBg
                    background #E9F3F7
            &.pouringMethod
                .pump-select
                    display flex
                    align-items flex-start
                    span
                        width 0.4rem
                        line-height .4rem
                        text-align center
                    .el-input
                        line-height 0.38rem
                        width calc((100% - 0.4rem)/2)
                        .el-input__inner
                            height .38rem
                            line-height .38rem
            .custom-table
                border-spacing 0px
                border-collapse collapse
                width 100%
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button
                    -webkit-appearance none !important
                td,th
                    height 0.48rem
                    font-size .16rem
                    padding 0.08rem
                    padding: 4px
                    border 1px solid #D7D7D7
                    box-shadow: inset 0px 0px 0px 4px #fff;
                th
                    font-weight 400
                    background: #F4F4F4

                td
                    .inputStyle1
                        width 0.8rem
                        .el-input__inner
                            height 0.32rem
                            line-height 0.3rem
                    .el-input
                        .el-input__suffix
                            line-height 0.38rem
                    .el-input
                        line-height 0.38rem
                    input
                        width 100%
                        font-size 0.14rem
                        height 0.38rem
                        line-height 0.38rem
                    .is-checked
                        .el-radio__inner
                            border-color: #409eff;
                            background: #409eff;
                    .el-radio__input.is-disabled+span.el-radio__label
                        color #333
                    .el-checkbox__input.is-disabled.is-checked
                        .el-checkbox__inner
                            background-color: #409EFF;
                            border-color: #409EFF;
                    .el-checkbox__input.is-disabled.is-checked
                        .el-checkbox__inner::after
                            border-color: #fff
                    .el-radio-group
                        margin-left 0.1rem
                    .price-input
                        width 1rem
                        margin 0 .1rem
                        input
                            width 1rem
                            height .38rem
                            border 1px solid #D7D7D7
                    .icon009
                        font-size .18rem
                    .no-tag
                        color #C6C6C6
                    .tag-con
                        .tag
                            width .8rem
                            border 1px solid #EAEAEA
                            height .32rem
                            line-height .32rem
                            margin-left .1rem
                            color #333
                            cursor pointer
                            background #F7F7F7
                            .tag-text
                                float left
                                width calc(100% - .24rem)
                                white-space nowrap
                                text-overflow ellipsis
                                overflow hidden
                                padding 0 .05rem
                            .iconguanbi
                                font-size .12rem
                                color #979EA7
                                float right
                                margin-right .1rem
                                width .14rem
                            &:hover
                                background #FEF8F8
                                border 1px solid #D6000F
                                color #D6000F
                                .iconguanbi
                                    color #D6000F
                        .tag-more
                            border 1px solid #0285FE
                            color #0285FE
                            border-radius .04rem
                            background #fff
                            text-align center
                            .tag-more-text
                                width 100%
                            &:hover
                                background #fff
                                border 1px solid #0285FE
                                color #0285FE
                                .iconguanbi
                                    color #0285FE
                    .del-btn
                        width .6rem
                        height .3rem
                        text-align center
                        line-height .3rem
                        color #D6000F
                        border 1px solid #D6000F
                        border-radius .04rem
                        display inline-block
                        cursor pointer
                    .sure-btn
                        width .6rem
                        height .3rem
                        text-align center
                        line-height .3rem
                        color #0285FE
                        border 1px solid #0285FE
                        border-radius .04rem
                        display inline-block
                        cursor pointer
                        margin-right .1rem
                    .el-input-number
                        width 100%
                        .el-input__inner
                            text-align left
                .table-tit
                    height 0.58rem
                    font-size 0.18rem
                    text-align: left
                    background: #eef3f8
                    padding 0
                    box-shadow none
                    background: none
                    padding-bottom 0.12rem
                    position: relative
                    border none
                    &:before
                        content ''
                        width 100%
                        height 2px
                        background #0062B5
                        position absolute
                        bottom 0.1rem
                        left 0
                    span
                        font-family: AliHYAiHei
                        font-size: 0.16rem;
                        color: #fff;
                        height: 0.46rem;
                        line-height: 0.46rem;
                        background: #0062B5;
                        padding: 0 0.2rem;
                        display: inline-block;
                        position: relative;
                        &:after
                            content:'';
                            width: 0.44rem;
                            height: 0.47rem;
                            background: url(./../../../../assets/images/popUpForm/tableHeaderBg.png) no-repeat;
                            background-size: 100%;
                            position: absolute;
                            left: 100%;
                .column-tit
                    text-align right
                    color #022782
                    background #F5F6F7
                    line-height 0.36rem
                .el-date-editor
                    width 100%
                    height 0.38rem
                    line-height 0.38rem
            .add-btn
                width 100%
                border 1px dashed #D7D7D7
                height .42rem
                margin-top .1rem
                text-align center
                line-height .4rem
                cursor pointer
                background #FCFCFC
                span
                    font-size .16rem
                    color #979EA7
                    margin-right .05rem
        .transportationCost
            margin-bottom 0
    footer
        height 0.8rem
        padding 0.2rem
        text-align center
        button
            width 2rem
            height 0.4rem
            line-height 0.42rem
            padding 0
            font 0.2rem '微软雅黑'
            margin 0 0.15rem
            border none
            cursor pointer
            &.save
                color #fff
                background #1577D2
            &.Close
                color #1577D2
                background #fff
                border 1px solid #1577D2
</style>